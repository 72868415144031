/**************************************************
 * Created by nanyuantingfeng on 23/11/2017 12:08.
 **************************************************/
import querystring from '@ekuaibao/lib/lib/querystring'
import { Fetch } from '@ekuaibao/fetch'
import createRequester from 'ekbc-reports/esm/common/createRequester'

const Requester = createRequester(Fetch, querystring)

export default Requester
