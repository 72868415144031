/**************************************************
 * Created by nanyuantingfeng on 05/12/2017 17:12.
 **************************************************/
// @i18n-ignore-all
export default [
  {
    id: '@reports',
    path: '/reports/enterprise',
    ref: '/',
    onload: () => import('./ReportEnterprise')
  },
  {
    path: '/reports/personal',
    ref: '/',
    onload: () => import('./ReportPersonal')
  },
  {
    path: '/reports/permissions',
    ref: '/',
    onload: () => import('./ReportPermissions')
  },
  {
    path: '/reports/detail/:id/:group',
    ref: '/',
    onload: () => import('./ReportDetail')
  },
  {
    point: '@@layers',
    prefix: '@reports',
    onload: () => import('./layers')
  },
  {
    point: '@@menus',
    onload: () => [
      { id: 'reports-new', weight: 3, label: '报表' },
      {
        id: 'reports-enterprise',
        pId: 'reports-new',
        permissions: ['REPORT_VIEW'],
        weight: 9,
        label: '企业报表',
        href: '/reports/enterprise',
        onClick() {
          window.TRACK && window.TRACK('EKBChart', { actionName: '查看' })
        }
      },
      {
        id: 'reports-personal',
        pId: 'reports-new',
        weight: 9,
        label: '个人报表',
        href: '/reports/personal',
        onClick() {
          window.TRACK && window.TRACK('EKBChart', { actionName: '查看' })
        }
      }
    ]
  },
  {
    resource: '@reports',
    value: {
      ['reports/Requester']: require('./Requester').default
    }
  }
]
